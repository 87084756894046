<template>
  <div class="engineering">
    <van-tabs sticky animated swipe-threshold='1' offset-top='146'>
      <van-tab v-for="(item, index) in Treelist" :key="index">
        <template #title>
          <span @click="onClickTabs(item)" class="engineeringSpan">{{
            item.prde03
          }}</span>
        </template>
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="getTreeInfo"
          :immediate-check="false"
        >
          <div v-if="list.length > 0">
            <van-swipe-cell v-for="(el, inx) in list" :key="inx">
              <div
                class="myDiv"
                style="
                  height: auto;
                  min-height: auto;
                  border-radius: 0;
                  padding: 15px;
                  margin-top: 15px;
                "
                @click="wxClick(el)"
              >
                <div class="flexLR">
                  <div>{{ inx + 1 }} . {{ el.user04 }}</div>
                  <div>
                    <van-icon name="ellipsis" @click="userPop(el)" size="20" />
                  </div>
                </div>
              </div>
              <template #right>
                <van-button
                  square
                  type="danger"
                  style="height: 100%; width: 100%"
                  text="删除"
                  @click="Delete(el)"
                />
              </template>
            </van-swipe-cell>
          </div>
          <div
            v-else
            style="color: #0a9efc; margin-top: 100px; text-align: center"
          >
            <img src="../../../assets/mobileImg/wu.png" width="60" alt="" />
            <div>暂无数据</div>
          </div>
        </van-list>
      </van-tab>
    </van-tabs>
    <van-dialog v-model="show">
      <div style="padding: 20px">
        <div class="flexLR" style="width: 100%; justify-content: start">
          <img
            style="border-radius: 10px"
            :src="$store.state.baseUrl + userInfo.user13"
            width="50"
            height="50"
            alt=""
          />
          <div style="margin: 0 20px">{{ userInfo.user04 }}</div>
          <img
            src="@//assets/mobileImg/sex_2.png"
            width="20"
            height="20"
            alt=""
          />
        </div>
        <div style="margin: 20px 0">
          <div>
            <van-field
              v-model="userInfo.user02"
              readonly
              label="帐号"
              label-width="40"
              colon
              placeholder="请输入用户名"
            />
          </div>
          <div>
            <van-field
              v-model="userInfo.pror02"
              readonly
              label="岗位"
              label-width="40"
              colon
              placeholder="请输入用户名"
            />
          </div>
          <div>
            <van-field
              v-model="userInfo.prde03"
              readonly
              label="组织"
              label-width="40"
              colon
              placeholder="请输入用户名"
            />
          </div>
        </div>
        <div class="flexLR" style="width: 100%">
          <div style="text-align: center; width: 50%">
            <van-icon name="phone-circle" color="#0089ff" size="40" />
            <div>{{ userInfo.user11 || "暂无绑定" }}</div>
          </div>
          <div style="text-align: center; width: 50%">
            <van-icon name="invitation" color="#0089ff" size="40" />
            <div>{{ userInfo.user12 || "暂无绑定" }}</div>
          </div>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { GetProjectdeptZTree, GetDeptUser, DelDataFile } from "@/api/index";
export default {
  name: "engineering",
  components: {},
  data() {
    return {
      loading: false,
      show: false,
      finished: false,
      pageSize: 0,
      list: [],
      Treelist: [],
      ids: "",
      userInfo: {},
    };
  },
  computed: {},
  watch: {},
  methods: {
    async Delete(v) {
      // let res = await DelDataFile({ fids: v.prfl01 });
      // this.getTreeInfo();
    },
    onClickTabs(v) {
      this.ids = v.prde01;
      this.getTreeInfo(v.prde01);
    },
    userPop(el) {
      this.show = true;
      this.userInfo = el;
    },
    wxClick(item) {
      // let data = {
      //   imgList: [],
      //   fileList: [],
      // };
      // let type = item.user13.substr(item.user13.indexOf(".") + 1);
      // if (type == "png" || type == "jpg") {
      //   data.imgList.push(this.$store.state.baseUrl + item.user13);
      // } else {
      //   data.fileList.push(this.$store.state.baseUrl + item.user13);
      // }
      // wx.miniProgram.navigateTo({
      //   url: `/pages/download/index?data=` + JSON.stringify(data),
      // });
    },
    async getData() {
      let res = await GetProjectdeptZTree({
        params: {
          id: this.$store.state.pid,
        },
      });
      let ids = res.Data.reduce((a, b, k) => {
        return a + b.prde01 + (res.Data.length - 1 == k ? "" : ",");
      }, "");
      res.Data.unshift({ prde01: ids, prde03: "全部", is: true });
      this.Treelist = res.Data;
      this.ids = ids;
      this.getTreeInfo(ids);
    },
    async getTreeInfo(ids) {
      this.loading = true;
      this.pageSize += 5;
      let res = await GetDeptUser({
        params: {
          xid: this.$store.state.pid,
          cid: this.$store.state.qid,
          bid: ids || this.ids,
          pageIndex: 1,
          pageSize: this.pageSize,
        },
      });
      this.list = res.Data.list;
      if (res.Data.list.length >= res.Data.total) {
        this.finished = true;
      }
      this.loading = false;
    },
    updateData() {
      this.list = [];
      this.Treelist = [];
      this.$emit("updateLoading", "工程资料");
      this.getData();
    },
  },
  created() {},
  mounted() {
    this.getData();
  },
};
</script>
<style lang='scss' scoped>
.engineering {
  width: 100%;
  height: 100vh;
  margin: 0 auto;

  .myDiv {
    width: 90%;
    margin: 10px auto;
    box-shadow: none;
  }
  /deep/ .van-tabs__line {
    background-color: rgb(255, 255, 255);
  }
  // /deep/.van-tab{
  //   min-width: 150px;
  // }
  /deep/ .van-tabs__nav {
    background-color: rgba(255, 255, 255, 0);
  }
  // /deep/ .van-tab{
  //   background-color: #2e6be6;
  // }
  // /deep/ .van-tabs__nav{
  //   margin-top:-6px;
  // }
  .engineeringSpan {
    color: white;
  }
  /deep/ .van-tab--active .van-tab__text .engineeringSpan {
    // position: relative;
    // z-index: 1;
    // width: 100px;
    // min-width: 62px;
    // overflow: auto;
    text-align: center;
    display: block;
    color: rgb(0, 119, 255);
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    padding: 0 5px;
  }
  /deep/ .van-tabs__content {
    height: 65%;
    overflow: hidden;
    overflow-y: auto;
  }
}
.headTree {
  width: 100%;
  height: 40px;
  overflow: hidden;
  overflow-y: auto;
  background-color: red;
}
/deep/ .van-sticky--fixed .van-tabs__nav {
  background-color: #3767ed !important;
}
// /deep/ .van-sticky--fixed {
//   top: 25%;
// }
/deep/ .van-sticky--fixed .van-tabs__line {
  opacity: 0;
}
</style>